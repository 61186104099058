<template>
  <div>
    <section class="acct--row acct--colt">
      <h2>{{ $t("account.account_my_collection") }}</h2>
      <div class="row">
        <div class="col-6 col-md-6 col-xl-4">
          <div class="ft-item" @click="openNewCollectionModal">
            <div class="ft-item--wrap">
              <div class="ft-item--inItem" href="">
                <div class="plus"></div>
                <p>
                  {{ $t("account.account_create_collection") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-6 col-md-6 col-xl-4"
          v-for="collection in collectionList"
          :key="collection.id"
        >
          <CollectionItem
            :fullWidthCheck="true"
            :name="lang == 'en' ? collection.title_en : collection.title_tc"
            :description="
              lang == 'en'
                ? collection.description_en
                : collection.description_tc
            "
            :imageName="
              collection.records.length > 0
                ? collection.records[0].gallery[0].image
                : 'media/hs-00003/hs-00003.jpg'
            "
          >
          </CollectionItem>

          <collectionEdit
            :collectionId="collection.id"
            :collectionSlug="collection.slug"
            @collectionList-afterDelete="apiCollectionItem"
          ></collectionEdit>
        </div>
      </div>
    </section>
    <newCollectionModal
      :collectionOpened="collectionModalOpened"
      @closeCollectionModal="handleCloseCollectionModal"
    >
    </newCollectionModal>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapState, mapActions } from "vuex";

import { authHeader } from "@/helpers/authHeader";
import CollectionItem from "@/components/masonry/CollectionItem";
import newCollectionModal from "@/components/collection/createNewCollectionModal";
import collectionEdit from "@/views/Collection/parts/collectionEdit";
export default {
  name: "collectionList",
  components: { CollectionItem, newCollectionModal, collectionEdit },
  data() {
    return {
      lang: Cookies.get("HKGA-MAINSITE-LANGUAGE"),
      collectionList: [],
      collectionModalOpened: false
    };
  },
  beforeMount() {
    this.apiCollectionItem();
  },

  methods: {
    ...mapActions("common", ["setPreventScrolling"]),

    openNewCollectionModal() {
      this.collectionModalOpened = !this.collectionModalOpened;
      this.setPreventScrolling(true);
    },
    handleCloseCollectionModal(value) {
      this.collectionModalOpened = value.modalState;
      this.setPreventScrolling(false);
      this.apiCollectionItem();
    },
    async apiCollectionItem() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/getUserCollection/`,
          {
            method: "GET",
            mode: "cors",
            cache: "default",
            headers: authHeader()
          }
        );
        if (!response.ok) {
          throw new Error("API Error");
        }

        const data = await response.json();

        this.collectionList = data;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 1s ease-in;
}
.slide-fade-leave-active {
  transition: all 1s ease-out;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
